<template>
  <div id="map" />
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { onMounted } from "vue";
import { createApp, defineComponent, nextTick } from 'vue';
import mixpanel from 'mixpanel-browser';
import MyPopupContent from '@/components/MyPopupContent.vue';
import { db, createEmployee, send_feedback } from './firebaseInit'  // eslint-disable-line

export default {
  setup() {
    onMounted(() => {
      mapboxgl.accessToken = process.env.VUE_APP_MAP_ACCESS_TOKEN
      mixpanel.track('enter_web');

      const map = new mapboxgl.Map({
        container: "map",
        center: [2.171212,41.393581],
        zoom: 3,
        style: process.env.VUE_APP_MAPBOX_STYLE_ID, // this is the style id of mapbox
      });

      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
          trackUserLocation: true
      }));
      
      map.on('load', () => {
        map.on("click", "worklocations", function (e) { // work-locations   is the name of the layer
          console.log(send_feedback);
          mixpanel.track('clickpopup');
          window.send_feedback = send_feedback;
          // https://blog.logrocket.com/how-to-build-and-deploy-a-vue-js-crud-app-with-cloud-firestore-and-firebase/
          debugger; // eslint-disable-line
          // e.features[0].id gets the id
          // e.features[0].properties gets the id
          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML('<div id="popup-content"></div>')
            .addTo(map);
          const MyNewPopup = defineComponent({
            extends: MyPopupContent,
            setup() {
              const title = 'This is the USA'
              return { title }
            },
          })
          nextTick(() => {
            createApp(MyNewPopup).mount('#popup-content')
          })
        });
      });
    });
    return {};
  },
};
</script>

<style>
#map {
  height: 100vh;
  width: 100vw;
}
</style>