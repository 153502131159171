import firebase from "firebase";
import mixpanel from 'mixpanel-browser';

const firebaseConfig = {
    apiKey: "AIzaSyAC-XkvdVPadccgl2fJJVhvOZr7TE_UCso",
    authDomain: "work-remotely-311121.firebaseapp.com",
    projectId: "work-remotely-311121",
    storageBucket: "work-remotely-311121.appspot.com",
    messagingSenderId: "1060885022885",
    appId: "1:1060885022885:web:264b05256696c1498c254c",
    measurementId: "G-NK88DCZSD6"
};
firebase.initializeApp(firebaseConfig); // eslint-disable-line
export var db = firebase.firestore();

export function createEmployee(name1) {
    db.collection("places")
    .add({ "name": name1 })
    .then(() => {   
        console.log("Document successfully written!");
    })
    .catch((error) => {
        console.log("Error writing document: ", error);
    });
}

export function send_feedback(feedback_text) {
    var feedback_id = Math.random().toString(36).slice(2);
    mixpanel.track('send_feedback', {"feedback_id": feedback_id, "feedback_text": feedback_text});
    db.collection("feedback")
    .add({ 
        "feedback_id": feedback_id,
        "feedback_text": feedback_text
    })
    .then(() => {   
        console.log("Document successfully written!");
    })
    .catch((error) => {
        console.log("Error writing document: ", error);
    });
}
