import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';

if (window.location.href.includes("localhost")) {
    console.log("running localhost without logrocket");
    mixpanel.init('d75dbef1154c46600e84e2d055f8ec64', {
        debug: true,
        opt_out_tracking_by_default: true,
        opt_out_persistence_by_default: true
    }); // dev
} else {
    LogRocket.init('hwshju/workremotely');
    mixpanel.init('4d115208e749f0c06fd6641a446e7a4a', {debug: true}); // prod
}

import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')
